// Decode the URL string passed into the App after a native QR Code Scan
// ***************
function getParameterByName(name, url) {
    if (!url) {
        url = window.location.href;
    }
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

// Today's date, in a nice/universal format (DD MMM YYYY)
// ***************
function todaysDate(){
    return new Date().toLocaleDateString('en-GB', {
        day : 'numeric',
        month : 'short',
        year : 'numeric'
    }).split(' ').join(' ');
}

// Add an action to a THNG
// ***************
function addAction(thng, action) {
  return thng.action(action).create();
}

function createRandomNumber() {
  var number = Math.random();
  var number = number.toString();
  var number = number.substring(2,6);
  return number;
}
