function readThng(user) {

  var userId = user.id;

    // Read thng, then product to get the product's ID and update image gallery
    // ***************
    user.thng(thngId).read().then(function(thng) {

      // When registration button clicked...
      $('#registerCard').submit(function(){
        // Add Registration Action
        addAction(thng, "_registration");
        // Update UI to block registration form
        $('.register-block').addClass('finished');
        // If optetd-in to Loyalty Scheme add additional action
        if($('#loyalty-check').is(':checked')) {
          addAction(thng, "_loyaltyProgramOptIn");
          // Launch 5% off modal
          $('#app-information').toggleClass('on');
        } else {
          // Give Thanks Toast message
          toaster('Thank you for registering your card.');
        }
          return false;
      });

      // If they click to search for locations
      $('.locationSearch').click(function(){
        // Add a Location Search action
        addAction(thng, "_locationSearch");
      });

      // Populate card credit information
      $('.card-details .card-credit').html(thng.properties.credit);

      // Check if the user has the THNG ID saved

      // If they don't
      if (localStorage.getItem("thng") === null) {

        // Add a New Card Scanned action
        addAction(thng, "_newCardScanned");

        // Go to Register State
        $('body').removeClass().addClass('register');

        // Lets create a random 4 digit card number for them
        var cardNumber = createRandomNumber();
        // And store it in localStorage
        localStorage.setItem('cardNumber', cardNumber);
        // Add the THNG ID to localStorage
        localStorage.setItem('thng', thng.id);


      // If they have a localStorage THNG
      } else {

        // Add a Owner Scan action
        addAction(thng, "_ownerScans");
        // Set the credit card cardNumber
        var cardNumber = localStorage.getItem("cardNumber");
        // Go to registered State
        $('body').removeClass().addClass('registered');

      }

      $('.card-details .card-number span').html(cardNumber);

      // Check if THNG has an owner
      // var ownerId = thng.customFields.owner;
      //
      // if(ownerId == 'none') {
      //
      //   // Go to Register State
      //   $('body').removeClass().addClass('register');
      //
      //   // Get the customFields so we can update the owner
      //   var customFields = thng.customFields;
      //   // Set a new value for the owner
      //   customFields.owner = userId;
      //
      //   // Update the THNG with the userId as the owner
      //   user.thng(thngId).read().then((thng) => {
      //     thng.customFields = customFields;
      //   });
      //
      // } else {
      //
      //   // If the userId matches the ownerId on the THNG
      //   if(ownerId == userId) {
      //
      //     $('body').removeClass().addClass('registered');
      //
      //   // If the userId DOESN'T match the ownerID on the THNG
      //   } else {
      //
      //     $('body').removeClass().addClass('not-owned');
      //
      //   }
      //
      // }

    }, function(error){

      // Go to registered State
      $('body').removeClass().addClass('scan');

      // Add a Failed Scan action if the thng isn't recognised
      var actions = {
        type: '_ScanFailed'
      };
      user.action('all').create(actions);

      toaster('Scan failed... please try again', 'error');

    });

}
