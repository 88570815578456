// Global set-up: get the THNG ID from the URL so we can read the THNG via the API
// ***************

var thngId = getParameterByName('thng'),
    apiKey = getParameterByName('app');

if(!apiKey){

  // Don't allow access to SCNTHNG (as we don't have an apiKey) - forward to URL with AppKey
  window.location.replace("https://goo.gl/scy6pB");

} else {

  // Set global app variable using Application API Key
  // ***************
  var app = new EVT.App(apiKey);

  // Once App variable set then process THNG and App config
  // ***************
  app.$init.then(app => {

    // Get the title from the App Name
    var appTitle = app.name;
    document.title = appTitle;

    // Create var for app ID to create session objects
    var appId = app.id;

    // Check / Create User
    getUser(appId).then(function(user) {

      // If THNG in URL
      if(thngId) {

        // If there's a THNG in the url process it
        readThng(user);

      // If no THNG in URL
      } else {

        // Go to Scan screen
        $('body').removeClass().addClass('scan');

      }

    });
    // End getUser;

  });

  $('.global-controls.right').click(function(){
    localStorage.removeItem("thng");
    localStorage.removeItem("cardNumber");
    toaster('localStorage Data Deleted');
    return false;
  });

}
